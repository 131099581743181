import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {STREAM_SERVER} from "./Constants";
import {parseQuery} from "./Home";
import {Button} from "antd";

export function loadGlobalField(result) {
    const fields = ['key', 'chapterId', 'comicId',"siteRoot","cdn","cdn2"]
    fields.forEach(field => {
        const regex = new RegExp(`gOpts\\.${field}=(.*?);`);
        const match = result.match(regex);
        if (match && match.length > 1) {
            window.gOpts[field] = match[1].replaceAll("'","").replaceAll('"',"");
        }
    });
}

const ViewChapter = () => {
    const {name,chapter,chapterKey} = useParams();
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState();
    const searchParamString = window.location.search;
    const params = parseQuery(searchParamString);
    const {comment} = params
    async function hackPhimmoi() {
        setLoading(true)
        try {
            let url = `${STREAM_SERVER}/nt?target=manga/${name}/${chapter}`;
            const response = await axios.get( url);
            setResult(response.data)
        } catch (e) {
        }
        setLoading(false)
    }

    useEffect(() => {
        hackPhimmoi()
    }, []);
    useEffect(() => {
        setTimeout(()=>{
            if(result){
                loadGlobalField(result);
                if(comment){
                    console.log('remove ne')
                    window.$('.comment-wrapper').removeClass("hidden")
                    window.reloadComment()
                }
            }
            window.STREAM_SERVER=STREAM_SERVER
            window.reloadScript()
            window.clearOptions()
        },500)
    }, [result]);
    return (
        <>
            <div dangerouslySetInnerHTML={{__html: result}}/>
            <Button type={"link"} onClick={() => {
                let currentUrl = window.location.href;
                let search = window.location.search;
                const newUrl = currentUrl.replace(search, "") + "?comment=true"
                window.location = newUrl;
            }}>Reload</Button>
        </>
    );
};

export default ViewChapter;
