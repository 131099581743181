import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {STREAM_SERVER} from "./Constants";
import axios from "axios";
import {loadGlobalField} from "./ViewChapter";
import {parseQuery} from "./Home";
import {Button} from "antd";

const Comics = () => {
    const {comicId, episode} = useParams();
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState();
    const searchParamString = window.location.search;
    const params = parseQuery(searchParamString);
    const {comment} = params
    useEffect(() => {
        if (episode) {
            let playerURl = `${STREAM_SERVER}/nt?target=${comicId}/${episode}`;
            console.log(playerURl)
            window.location = playerURl
        } else {
            comicId && hackPhimmoi(comicId)
        }

    }, [comicId, episode]);
    const navigate = useNavigate();

    async function hackPhimmoi() {
        setLoading(true)
        try {
            const response = await axios.get(`${STREAM_SERVER}/nt?target=truyen-tranh/${comicId}/`);
            setResult(response.data)
        } catch (e) {
        }
        setLoading(false)
    }

    useEffect(() => {
        setTimeout(() => {
            if (result) {
                loadGlobalField(result)
                if (comment) {
                    console.log('remove ne')
                    window.$('.comment-wrapper').removeClass("hidden")
                    window.reloadComment()
                }
            }
            window.STREAM_SERVER = STREAM_SERVER
            window.reloadScript()
        }, 1000)
    }, [result]);
    return (
        <>
            <div dangerouslySetInnerHTML={{__html: result}}/>
            <Button type={"link"} onClick={() => {
                let currentUrl = window.location.href;
                let search = window.location.search;
                const newUrl = currentUrl.replace(search, "") + "?comment=true"
                window.location = newUrl;
            }}>Reload</Button>
        </>
    );
};

export default Comics;
