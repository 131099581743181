import React, {useEffect, useState} from 'react';
import {STREAM_SERVER} from "./Constants";
import axios from "axios";
import {loadGlobalField} from "./ViewChapter";
import {useParams} from "react-router-dom";

export function parseQuery(queryString) {
    if (queryString?.length)
        return JSON.parse('{"' + queryString.replace('?&', '').replace('?', '').replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        });
    return {}
}

const Home = () => {
    const {path, subPath} = useParams();
    const searchParamString = window.location.search;
    const params = parseQuery(searchParamString);
    const {page, query, status, sort} = params
    const [result, setResult] = useState()

    async function handleSearch({query, page, target, filter}) {
        try {

            let url = `${STREAM_SERVER}/nt?target=${target}${searchParamString}`;
            console.log(searchParamString)
            const response = await axios.get(url);
            setResult(response.data)
        } catch (e) {
        }
    }

    useEffect(() => {
        if (subPath) {
            handleSearch({target: `${path}/${subPath}`, page})
        } else {
            handleSearch({target: path || "", page})
        }

    }, []);
    useEffect(() => {
        result && setTimeout(() => {
            if (result) {
                loadGlobalField(result)
                window.STREAM_SERVER = STREAM_SERVER

            }
            console.log('about to reload')
            window.reloadScript()

        }, 1000)
    }, [result]);
    return (
        <div dangerouslySetInnerHTML={{__html: result}}/>
    );
};

export default Home;
